$brand: #FECD00;
$brand-lighter: #FFF036;
$brand-darker: #FEE133;
$text-shadow: #707070;
$gray: #404141;
$light-gray: #EAEAEA;
$medium-gray: #C9C9C9;
$disabled-gray-shadow: #C6C5C5;
$gradient-green-start: #50F442;
$gradient-green-end: #21C412;
$blue: #2BCCFC;
$white: #ffffff;
$black: #282828;

$button-start: #81EBFC;
$button-end: #05CBFC;
$button-shadow: #21AFFC;

$error-button-start: #FA7070;
$error-button-end: #FA4C48;
$error-button-shadow: #D4211C;

$correct-button-start: #50F442;
$correct-button-end: #39DF29;
$correct-button-shadow: #2DB22E;

$font-base: 'DIN Next Rounded LT Pro', sans-serif;



