@import "src/scss/abstracts/variables";

.heart-wrapper {
  position: absolute;
  z-index: 10;
  left: 1%;
  top: 20%;
  width: 115px;
  height: 300px;
  background-color: $white;
  border-radius: 40px;
  border-bottom: 8px solid $medium-gray;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-around;
  align-items: center;
}

.life {
  display: block;
  position: relative;
  width: 60px;
  height: 60px;
  margin: 0 auto;
}

.heart {
  fill: #AABBC2;
  width: 60px;
  height: 60px;
  display: block;
  position: relative;
  z-index: 1;
}

.active .heart {
  fill: #FF4D70;
  -webkit-transform: scale(0);
  transform: scale(0);
  animation: heart 5s cubic-bezier(0.5, 0.1, 0.7, 0.8) 0.3s forwards infinite;
}

.circle {
  width: 10px;
  height: 10px;
  position: absolute;
  border-radius: 50%;
  top: 30px;
  left: 30px;
  border: 5px solid #E2264D;
}

.inactive .circle {
  animation: circle 1s forwards;
}

.drop {
  background: #FF99AD;
  width: 0;
  height: 0;
  margin-top: 20px;
  position: absolute;
  left: 7px;
  top: -13px;
  opacity: 1;
  border-radius: 50%;
}

.inactive .drop {
  background: #FF4D70;
  -webkit-transition: all 0.3s cubic-bezier(0.5, 0, 1, 1) 0.1s;
  transition: all 0.3s cubic-bezier(0.5, 0, 1, 1) 0.1s;
  animation: drop 0.3s linear 2s forwards;
}

.drop:before {
  content: "";
  display: block;
  background: #FF4D70;
  width: 0px;
  height: 0px;
  position: absolute;
  top: -5px;
  left: -5px;
  border-radius: 50%;
}

.inactive .drop:before {
  animation: drop 2s linear 0.2s forwards;
}

.drop:nth-child(1) {
  top: 0;
  left: 27px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.drop:nth-child(2) {
  top: 0;
  left: 30px;
  -webkit-transform: rotate(96deg);
  transform: rotate(96deg);
}

.drop:nth-child(3) {
  top: 5px;
  left: 30px;
  -webkit-transform: rotate(147deg);
  transform: rotate(147deg);
}

.drop:nth-child(4) {
  top: 13px;
  left: 28px;
  -webkit-transform: rotate(198deg);
  transform: rotate(198deg);
}

.drop:nth-child(5) {
  top: 5px;
  left: 25px;
  -webkit-transform: rotate(249deg);
  transform: rotate(249deg);
}

.drop:nth-child(6) {
  top: 3px;
  left: 25px;
  -webkit-transform: rotate(300deg);
  transform: rotate(300deg);
}

.drop:nth-child(7) {
  top: 2px;
  left: 25px;
  -webkit-transform: rotate(351deg);
  transform: rotate(351deg);
}

.inactive .drop:nth-child(1) {
  top: -45px;
  left: 35px;
  -webkit-transform: rotate(-115deg);
  transform: rotate(-115deg);
}

.inactive .drop:nth-child(2) {
  top: -20px;
  left: 70px;
  -webkit-transform: rotate(-59deg);
  transform: rotate(-59deg);
}

.inactive .drop:nth-child(3) {
  top: 35px;
  left: 70px;
  -webkit-transform: rotate(-4deg);
  transform: rotate(-4deg);
}

.inactive .drop:nth-child(4) {
  top: 60px;
  left: 25px;
  -webkit-transform: rotate(47deg);
  transform: rotate(47deg);
}

.inactive .drop:nth-child(5) {
  left: -20px;
  top: 35px;
  -webkit-transform: rotate(98deg);
  transform: rotate(98deg);
}

.inactive .drop:nth-child(6) {
  top: -5px;
  left: -20px;
  -webkit-transform: rotate(149deg);
  transform: rotate(149deg);
}

.inactive .drop:nth-child(7) {
  top: -35px;
  left: -5px;
  -webkit-transform: rotate(200deg);
  transform: rotate(200deg);
}

/* ANIMATIONS */

@-webkit-keyframes beat {
  0% {
    transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  80% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes beat {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  70% {
    transform: scale(1);
  }
  80% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes heart {
  0% {
    transform: scale(1);
  }
  15% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  20% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  30% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  60% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  70% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  80% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  90% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes heart {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  15% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  20% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  30% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  60% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  70% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  80% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  90% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes circle {
  0% {
    border: 5px solid #E2264D;
    width: 10px;
    height: 10px;
  }
  80% {
    border: 35px solid #FF4D70;
    width: 100px;
    height: 100px;

  }
  100% {
    border-width: 0px;
    width: 100px;
    height: 100px;

  }
}

@keyframes circle {
  0% {
    border: 5px solid #E2264D;
    width: 10px;
    height: 10px;
  }
  80% {
    border: 35px solid #FF4D70;
    width: 100px;
    height: 100px;
    top: -25px;
    left: -20px;
  }
  100% {
    border-width: 0px;
    width: 100px;
    height: 100px;
    top: -25px;
    left: -20px;
  }
}

@-webkit-keyframes drop {
  0% {
    width: 0px;
    height: 0px;
  }
  10% {
    width: 5px;
    height: 5px;
  }
  90% {
    width: 5px;
    height: 5px;
  }
  100% {
    width: 0px;
    height: 0px;
  }
}

@keyframes drop {
  0% {
    width: 1px;
    height: 1px;
  }
  10% {
    width: 0px;
    height: 0px;
  }
  90% {
    width: 5px;
    height: 5px;
  }
  100% {
    width: 0px;
    height: 0px;
  }
}