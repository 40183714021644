@import '../../scss/abstracts/_variables.scss';

.answer-button {
  background-color: $white;
  position: relative;
  z-index: 10;
  border-radius: 25px;
  box-shadow: 0 10px 0 0 $brand-lighter;
  padding: 30px 30px 30px 65px;
  font-weight: bold;
  font-size: 28px;
  border: 4px solid $white;
  width: 100%;
  font-family: $font-base;

  &::before {
    background: $white;
    position: absolute;
    content: 'A';
    border-radius: 25px;
    box-shadow: 0 5px 0 0 $light-gray, 0 0 10px $light-gray;
    padding: 20px 30px 10px 30px;
    font-size: 56px;
    line-height: 56px;
    left: 0;
    top: 50%;
    transform: translate(-30%, -50%);

  }

  &:nth-of-type(2):before {
    content: 'B';
  }

  &:nth-of-type(3):before {
    content: 'C';
  }

  &:nth-of-type(4):before {
    content: 'D';
  }
}

.selected {
  border-color: $button-start;
  box-shadow: 0 10px 0 0 $button-shadow;


  &::before {
    background: linear-gradient($button-start, $button-end);
    box-shadow: 0 5px 0 0 $button-shadow, 0 0 10px $light-gray;
    color: $white;
  }
}

.correct {
  border-color: $correct-button-start;
  box-shadow: 0 10px 0 0 $correct-button-shadow;


  &:nth-of-type(n)::before {
    background: linear-gradient($correct-button-start, $correct-button-end);
    box-shadow: 0 5px 0 0 $correct-button-shadow, 0 0 10px $light-gray;
    color: $white;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='55.412' height='55.683' viewBox='0 0 130.412 120.683'%3E%3Cpath id='Path_92' data-name='Path 92' d='M88.692,153l33.363,30.661,61.917-84.554' transform='translate(-71.03 -81.637)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-width='25'/%3E%3C/svg%3E%0A");
  }
}

.wrong {
  border-color: $error-button-start;
  box-shadow: 0 10px 0 0 $error-button-shadow;


  &:nth-of-type(n)::before {
    background: linear-gradient($error-button-start, $error-button-end);
    box-shadow: 0 5px 0 0 $error-button-start, 0 0 10px $light-gray;
    color: $white;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50.131' height='50.131' viewBox='0 0 120.131 120.131'%3E%3Cg id='Group_487' data-name='Group 487' transform='translate(-54.934 -82.934)'%3E%3Cpath id='Path_93' data-name='Path 93' d='M83.2,99.107l33.446,33.447,51.329,51.329' transform='translate(-10.586 1.505)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-width='25'/%3E%3Cpath id='Path_92' data-name='Path 92' d='M167.974,99.107l-33.447,33.447L83.2,183.883' transform='translate(-10.587 1.505)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-width='25'/%3E%3C/g%3E%3C/svg%3E%0A");
  }
}