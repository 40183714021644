@import "src/scss/abstracts/variables";

.timer {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  border-radius: 50px;
  box-shadow: 0 0 0 10px $white, 0 15px 0 $medium-gray;
  background-color: $white;

  &.hurry {
    box-shadow: 0 0 0 10px $error-button-start, 0 15px 0 $error-button-shadow;
    animation: 1s ease-in-out infinite shake;
  }

  &.over {
    box-shadow: 0 0 0 10px $error-button-start, 0 15px 0 $error-button-shadow;
  }


  .inner {
    position: relative;
    height: 100%;
    margin: auto 0;
    background: linear-gradient($button-start, $button-end);
    box-shadow: 0 5px 0 0 $button-shadow;
    border-radius: 50px;
    z-index: 5;
    transition: all .25s;

    &:before {
      position: absolute;
      top: 10px;
      left: 10px;
      content: ' ';
      width: 10px;
      height: 10px;
      background: $white;
      box-shadow: 0 0 10px $white;
      transform: skew(-20deg);
      border-radius: 20px;
    }


  }

  &::after {
    position: absolute;
    content: ' ';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    background: url("./Mask Group 1.png");
    background-size: 100% 100%;
  }
}

.counter {
  position: absolute;
  z-index: 10;
  font-family: $font-base;
  color: $gray;
  top: 50%;
  left: 50%;
  font-size: 20px;
  transform: translate(-50%, -50%);
}


@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(0deg);
  }
  85% {
    transform: rotate(3deg);
  }
  95% {
    transform: rotate(-3deg);
  }
  100% {
    transform: rotate(0deg);
  }
}