@import "src/scss/abstracts/variables";

.coin-holder {
  position: fixed;
  right: 0;
  bottom: -30px;
  z-index: 10;
  width: 200px;

  svg {
    width: 200px;
  }
}


