@import "src/scss/abstracts/variables";

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

.clouds {
}

.wrapper {
  overflow-x: hidden;
  z-index: 0;

  background-color: $brand;
}

.cloud {
  animation: clouds 10s infinite linear;
  border-radius: 10px;
  position: relative;
  margin: 15px 0 0 0;
  z-index: -1;

  width: 54px;
  height: 5px;

  background: #f7e7eb;

  &.tiny {
    transform: scale(.5);
  }

  &.small {
    transform: scale(1);
  }

  &.normal {
    transform: scale(2);
  }

  &.large {
    transform: scale(4);
  }

  div {
    box-shadow: inset -2px -3px 0 0 #f7e7eb;
    position: absolute;

    border-radius: 50%;
    width: 12px;
    height: 12px;

    left: -3px;
    bottom: 0;

    background: #fafbf0;
    z-index: -1;

    &:first-child {
      & + div {
        transform: scale(1.6, 1.6);
        margin: 0 0 4px 13px;
        z-index: -2;

        & + div {
          transform: scale(2.4, 2.4);
          margin: 0 0 9px 32px;
          z-index: -3;

          & + div {
            transform: scale(1.3, 1.3);
            margin: 0 0 2px 50px;
            z-index: -4;
          }
        }
      }
    }
  }

  &.secondary {
    background: #FFE834;

    div {
      box-shadow: inset -2px -3px 0 0 $brand-darker;
      background: #FFE834;
    }
  }
}

@include keyframes(clouds) {
  0% {
    left: -100%;
    opacity: 0.1;
  }
  2% {
    opacity: 1;
  }
  50% {
    transform: scale(2);
  }
  85% {
    opacity: 1;
    transform: scale(3);
  }
  98% {
    left: 70%;
    opacity: 0;
  }
  100% {
    left: -100%;
    opacity: 0;
    transform: scale(1);
  }
}

.cloud-1 {
  animation-duration: 50s;
  margin-top: 10%;
  margin-left: 20%;
}

.cloud-2 {
  animation-duration: 40s;
  margin-top: 5%;
  margin-left: 70%;
}

.cloud-3 {
  animation-duration: 60s;
  margin-left: 110%;
}

.cloud-4 {
  animation-duration: 75s;

  margin-left: 123%;
}

.cloud-5 {
  animation-duration: 65s;
  margin-top: 2%;
  margin-left: 113%;
}

.cloud-6 {
  animation-duration: 50s;
  margin-left: 153%;
}

.cloud-15 {
  animation-duration: 85s;
  margin-left: 123%;
}

.cloud-16 {
  animation-duration: 69s;
  margin-left: 145%;
}

.cloud-7 {
  animation-duration: 72s;
  margin-left: 0;
}

.cloud-8 {
  animation-duration: 60s;
  margin-top: 10%;
  margin-left: 110%;
}

.cloud-9 {
  animation-duration: 80s;
  margin-left: 130%;
}

.cloud-10 {
  animation-duration: 65s;
  margin-left: 65%;
}

.cloud-11 {
  animation-duration: 96s;
  margin-left: 93%;
}

.cloud-13 {
  animation-duration: 96s;
  margin-left: 173%;
}

.cloud-14 {
  animation-duration: 96s;
  margin-left: 43%;
}

.cloud-12 {
  animation-duration: 73s;
  margin-left: 82%;
}

.cloud-17 {
  animation-duration: 73s;
  margin-left: 72%;
}

.cloud-18 {
  animation-duration: 73s;
  margin-left: 172%;
}
