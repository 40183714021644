@import "src/scss/abstracts/variables";

.card {
  box-shadow: 0 10px 0 $brand-lighter;
  border-radius: 40px;
  background-color: $white;
  min-width: 325px;
  display: flex;
  justify-content: center;
  z-index: 10;
  font-family: $font-base;
  color: $gray;
}

.secondary {
  background-color: $brand-lighter;
  box-shadow: 0 10px 0 $brand-darker;

  &:hover {
    animation: shake 1s;
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(0, 0);
  }
}