$score: attr(data-score);
#score {
  position: absolute;
  top: 1%;
  right: 0;
  width: 500px;
  height: 800px;

  & > svg {
    width: 100%;
    height: 100%;
    z-index: 10;
  }

}

#liquid {

  line-height: $score;
}




