@import "src/scss/abstracts/variables";

.closed {
  box-shadow: inset 0 3px 0 #00000016;
  text-shadow: 0 3px 0 #00000016;
  background-color: $brand-darker;
}

.bonus {
  text-shadow: 0 3px 0 #00000016;
}

.marks {
  background-image: url('./asstes-bg-questions.png');
  background-size: contain;
  animation: pulse 4s linear infinite;
}

@keyframes pulse {
  0% {
    background-position-y: -20px;
  }
  50% {
    background-position-y: 0;
  }
  100% {
    background-position-y: -20px;
  }
}

.explosion {
  animation: 2s ease-in explode;
  opacity: 1;

}

@keyframes explode {
  0% {
    opacity: 0;
    transform: scale(.2) translateX(-115%);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateX(-55%);
  }
}
