@import 'src/scss/abstracts/variables';

.wrapper {
  overflow-x: hidden;
  min-height: 100vh;
  background-image: url("assets.png");
  background-size: contain;
  animation: float 20s infinite linear;
}

@keyframes float {
  0% {
    background-position-x: -40px;
  }
  50% {
    background-position-x: 40px;
  }
  100% {
    background-position-x: -40px;
  }
}