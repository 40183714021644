.slide {
  animation: 1.5s ease-in forwards slideInDown;
  opacity: 1;
}

.wrapper {
  height: 680px;
  width: 800px;
}

@keyframes slideInDown {
  0% {
    opacity: 1;
    transform: translate(0, 130%);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}
