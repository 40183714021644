@import "src/scss/abstracts/variables";

.banner {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  margin: 10px auto;
  min-width: 600px;
  min-height: 80px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: $font-base;
  font-size: 54px;
  line-height: 60px;
  font-weight: bold;
  text-shadow: 0 5px 0 $gray;
  text-transform: uppercase;
  padding-top: 12px;


  &.red {
    background-image: url("./flag-red.png");
  }

  &.green {
    background-image: url("./flag-green.png");
  }

  &.blue {
    background-image: url("./fag-blue.png")
  }
}